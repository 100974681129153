import { information } from "../const/info.const";
import { encrypt } from "../controllers/crypto.controller";

// export const getCodeClient = async (promo_code) => {
//   const { endpoint, site } = information;
//   let myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");

//   let objParam = {
//     promo_code: promo_code,
//   };
//   let encryptHandle = encrypt(JSON.stringify(objParam));
//   let raw = JSON.stringify({
//     key: encryptHandle,
//   });
//   let requestOptions = {
//     method: "POST",
//     redirect: "follow",
//     headers: myHeaders,
//     body: raw,
//   };
//   return await fetch(endpoint + "/client/get-code?promo_code=" + promo_code + "&site=" + site, requestOptions)
//     .then((response) => response.json())
//     .then((result) => {
//       return result;
//     })
//     .catch((error) => console.log("error", error));
// };



export const getCodeClient = async (promo_code, captchaInput, clientToken) => {
  const { endpoint, site } = information;
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", clientToken);

  let objParam = {
    promo_code: promo_code,
  };
  let encryptHandle = encrypt(JSON.stringify(objParam));
  let raw = JSON.stringify({
    key: encryptHandle,
    captchaCode: captchaInput,
    token: clientToken,
  });
  let requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders,
    body: raw,
  };
  return await fetch(endpoint + "/client/get-code?promo_code=" + promo_code + "&site=" + site, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};

export const addPointClient = async (player_id, promo_code) => {
  const { endpoint, site } = information;
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let objParam = {
    promo_code: promo_code,
  };

  let encryptHandle = encrypt(JSON.stringify(objParam));
  let raw = JSON.stringify({
    key: encryptHandle,
  });

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders,
    body: raw,
  };

  return await fetch(endpoint + "/client?player_id=" + player_id + "&promo_code=" + promo_code + "&site=" + site, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};

export const getCaptchaTokenClient = async () => {
  const { endpoint, site } = information;
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let requestOptions = {
    method: "get",
    redirect: "follow",
    headers: myHeaders,
  };
  return await fetch(endpoint + "/api/get-verification-code?site=" + site, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};

export const verifyCaptchaTokenClient = async (captchaInput, clientToken) => {
  const { endpoint, site } = information;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", clientToken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    captchaCode: captchaInput,
    token: clientToken,
  });

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders,
    body: raw,
  };
  return await fetch(endpoint + "/api/verify-captcha?site=" + site, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};
