import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../assets/images/logo.png";
import ticket from "../assets/images/ticket.svg";
import "../assets/styles/style.css";
import Modal from "./Modal";
import homeIcon from "../assets/images/home.png";
import homePC from "../assets/images/home-pc.png";

import tablet from "../assets/images/tablet.png";
import {
  addPointClient,
  getCaptchaTokenClient,
  getCodeClient,
} from "../services/api.service";
import Swal from "sweetalert2";
import moment from "moment-timezone";

import { information } from "../const/info.const";
const PromoPage = () => {
  const [promoCode, setPromoCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [captchaBase64, setCaptchaBase64] = useState("");

  useEffect(() => {
    if (captchaBase64) {
      document.getElementById("captcha-image").src = captchaBase64;
    }
  }, [captchaBase64]);

  const handleCheckCode = async () => {
    if (!promoCode.trim()) {
      setWarningMessage("Mã CODE không được bỏ trống");
      return;
    }
    let valueCode = promoCode.trim();
    Swal.fire({
      titleText: "Đang kiểm tra ...",
      color: "#ffffff",
      allowOutsideClick: false,
      background: `linear-gradient(#d73316cc,#ff9800cf)`,
      width: 320,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      let loadCaptcha = await getCaptchaTokenClient();
      setCaptchaBase64(loadCaptcha.captchaUrl);
      document.cookie = `token=${loadCaptcha.token}; max-age=180; path=/`;
      let tokenCaptcha = loadCaptcha.token;
      Swal.fire({
        title: "Mã Xác Thực",
        showConfirmButton: false,
        allowOutsideClick: false,
        html: `
        <div class="input-form-captcha-wrapper">
          <div class="thumb-captcha">
            <img id="captcha-image" class="image-captcha notranslate" src="" alt="Captcha Image">
          </div>
          <div class="input-form-captcha">
            <input class="notranslate" translate="no" type="text" placeholder="Nhập mã captcha" id="captcha-input">
            </input>
          </div>
        </div>
        <button id="verify-captcha" class="swal2-confirm swal2-styled">Xác Thực</button>
      `,
      });

      document.getElementById("captcha-image").src = loadCaptcha.captchaUrl;
      document
        .getElementById("captcha-image")
        .addEventListener("click", async () => {
          let newCaptcha = await getCaptchaTokenClient();
          setCaptchaBase64(newCaptcha.captchaUrl);
          tokenCaptcha = newCaptcha.token;
          document.cookie = `token=${newCaptcha.token}; max-age=180; path=/`;
          document.getElementById("captcha-image").src = newCaptcha.captchaUrl;
        });

      document
        .getElementById("verify-captcha")
        .addEventListener("click", async () => {
          const captchaInputValue =
            document.getElementById("captcha-input").value;
          let codeResult = await getCodeClient(
            valueCode,
            captchaInputValue,
            tokenCaptcha
          );
          if (codeResult.status_code === 403) {
            Swal.fire({
              icon: "error",
              allowOutsideClick: false,
              title: codeResult.title_mess,
              background: `linear-gradient(#FFEFE7,#e7d8d8)`,
              html:
                "<div class='modal-text-wrapper'>" +
                "<div class='modal-text-cont'>" +
                "<p class='modal-text'>Mã CODE: &nbsp;</p>" +
                "<p style='font-weight: 600'>" +
                codeResult.detail.promo_code +
                "</p>" +
                "</div>" +
                "<div class='modal-text-cont'>" +
                "<p class='modal-text'>Điểm thưởng: &nbsp;</p>" +
                "<p style='font-weight: bold; color:#ec164c'>" +
                codeResult.detail.point +
                "&nbsp; điểm</p>" +
                "</div>" +
                "</div>",
              footer:
                '<a href="' +
                information?.cskh_url +
                '" target="_blank">Chăm sóc khách hàng 24/7</a>',
            }).then(() => {
              window.location.reload();
            });
          } else if (codeResult.status_code === 400) {
            Swal.fire({
              icon: "error",
              title: "Lỗi xác thực",
              text: "Mã captcha không đúng. Vui lòng thao tác lại.",
            });
            return;
          } else {
            if (codeResult.valid === false) {
              Swal.fire({
                icon: "warning",
                title: codeResult.title_mess,
                text: codeResult.text_mess,
                confirmButtonText: "Thử lại",
                footer:
                  '<a href="' +
                  information?.cskh_url +
                  '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
              }).then(() => {
                window.location.reload();
              });
            } else if (codeResult.valid === true) {
              let time = moment(codeResult.detail.time)
                .tz("Asia/Ho_Chi_Minh")
                .format("DD/MM/YYYY, HH:mm:ss");
              const { value: input_data } = await Swal.fire({
                allowOutsideClick: false,
                html: `
                  <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 16px;">
                    <div class="outer-circle">
                      <div class="inner-circle">
                        <div class="checkmark"></div>
                      </div>
                    </div>
                  </div>
                  <h2>${codeResult.title_mess}</h2>
                  <table style="width: 100%; margin-bottom: 16px; text-align: left;">
                    <tbody>
                      <tr>
                        <td>Mã CODE:</td>
                        <td class="align-right"><strong>${codeResult?.detail?.promo_code}</strong></td>
                      </tr>
                      <tr>
                        <td>Điểm thưởng:</td>
                        <td class="pointbonus align-right"><strong>${codeResult?.detail?.point} điểm </strong></td>
                      </tr>
                      <tr>
                        <td>${codeResult?.detail?.mess}:</td>
                        <td class="align-right">${time}</td>
                      </tr>
                    </tbody>
                  </table>
                `,
                footer:
                  '<a href="' +
                  information?.cskh_url +
                  '" target="_blank">Chăm sóc khách hàng 24/7</a>',
                input: "text",
                inputLabel: "Vui lòng nhập tên tài khoản để nhận thưởng",
                inputPlaceholder: "Tên tài khoản",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                confirmButtonText: "Xác nhận",
                inputValidator: async (value) => {
                  if (!value) {
                    return "Vui lòng nhập tên tài khoản!";
                  }
                },
              });
              if (input_data) {
                Swal.fire({
                  text: "Đang cộng điểm ...",
                  allowOutsideClick: false,
                  width: 300,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });
                let addPoint = await addPointClient(
                  input_data,
                  codeResult.detail.promo_code
                );
                if (addPoint.status_code === 502) {
                  Swal.fire({
                    icon: "warning",
                    title: addPoint.title_mess,
                    text: addPoint.text_mess,
                    footer:
                      '<a href="' +
                      information?.cskh_url +
                      '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
                  }).then(() => {
                    window.location.reload();
                  });
                } else {
                  if (addPoint.valid === false) {
                    Swal.fire({
                      icon: "error",
                      title: addPoint.title_mess,
                      text: addPoint.text_mess,
                      footer:
                        '<a href="' +
                        information?.cskh_url +
                        '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
                    }).then(() => {
                      window.location.reload();
                    });
                  } else if (addPoint.valid === true) {
                    await Swal.fire({
                      html: `
                        <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 16px;">
                          <div class="outer-circle">
                            <div class="inner-circle">
                              <div class="checkmark"></div>
                            </div>
                          </div>
                        </div>
                        <h2>Chúc mừng</h2>
                        <p>
                          Chúc mừng <strong class="pointbonus">${addPoint?.player_id}</strong> đã nhận thành công 
                          <span style="color: red; font-weight: bold;">${addPoint?.point} điểm</span>
                        </p>
                        <div class="pyro" id="pyro">
                          <div class="before"></div>
                          <div class="after"></div>
                          <div class="before"></div>
                          <div class="after"></div>
                          <div class="before"></div>
                          <div class="after"></div>
                        </div>
                      `,
                      showCancelButton: false,
                      confirmButtonText: "TIẾP TỤC",
                      customClass: {
                        confirmButton: "popup-button confirm",
                      },
                      allowOutsideClick: false,
                    }).then(() => {
                      window.location.reload();
                    });
                  }
                }
              }
            }
          }
        });
    } catch (error) {
      console.error("Error fetching code:", error);
    }
    //  finally {
    //   Swal.close(); // Ensure Swal is closed after the operation
    // }
  };

  const handleLinkClick = () => {
    setIsModalOpen(true);
  };

  const handleHomeClick = () => {
    window.location.href = information?.cskh_home;
  };

  return (
    <div className="body">
      <div className="modal-custom"></div>
      <div
        className="promo-page"
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span onClick={handleHomeClick} className="home-button">
          {/* <span className="home-text">Trang chủ</span> */}
          <img src={homePC} alt="Home Icon" className="home-icon-pc" />
          <img src={homeIcon} alt="Home Icon" className="home-icon" />
        </span>
        <div className="body-content">
          <div className="thumb-logo">
            <img src={logo} alt="NEW88" className="promo-logo" />
          </div>
          <div className="promo-form">
            <h1 className="promo-title">NHẬP CODE NHẬN THƯỞNG</h1>
            <div className="input-container">
              <label className="promo-label" htmlFor="promo-code">
                <img src={ticket} alt="Ticket Icon" className="coupon-icon" />
                <span className="label-text">MÃ CODE</span>
              </label>
              <input
                id="promo-code"
                type="text"
                placeholder="Nhập mã CODE"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                className="promo-input"
              />
              <button onClick={handleCheckCode} className="promo-button">
                KIỂM TRA NGAY
              </button>
              {warningMessage && (
                <p className="warning-message">{warningMessage}</p>
              )}
              <div className="promo-links">
                <p
                  className="promo-link promo-link-a"
                  onClick={() => handleLinkClick()}
                >
                  Thông tin chương trình{" "}
                  <span className="arrow">
                    <svg
                      width="40"
                      height="41"
                      viewBox="0 0 40 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.024 0C10.704 0 2.38918 6.69891 0.439559 15.8209C-0.534485 20.3789 0.121681 25.2256 2.29478 29.3499C4.38625 33.319 7.81505 36.5269 11.9174 38.3449C16.1845 40.2363 21.0833 40.5659 25.5683 39.2761C29.8951 38.0321 33.7316 35.3018 36.3435 31.6368C41.8045 23.9745 41.0744 13.2116 34.6495 6.34583C30.8839 2.32209 25.5356 0 20.024 0ZM29.6313 21.0968L24.1717 26.688C22.7895 28.1039 20.6047 25.9589 21.9813 24.5496L24.7361 21.7285H11.6811C10.754 21.7285 9.9805 20.9544 9.9805 20.0279C9.9805 19.1013 10.7545 18.3272 11.6811 18.3272H24.6708L21.8614 15.5184C20.4654 14.1224 22.6298 11.9574 24.0258 13.3534L29.6185 18.9456C30.2114 19.538 30.217 20.4973 29.6313 21.0968Z"
                        fill="url(#paint0_linear_1_231)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1_231"
                          x1="0"
                          y1="20.0251"
                          x2="40"
                          y2="20.0251"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF9800" />
                          <stop offset="1" stop-color="#FF4929" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                </p>
                <p className="promo-link">
                  <a
                    className="promo-link-a"
                    style={{ padding: 0 }}
                    target="_blank"
                    href="https://t.me/CodeFreeNew88"
                    rel="noreferrer"
                  >
                    Trang phát code
                    <span className="arrow">
                      <svg
                        width="40"
                        height="41"
                        viewBox="0 0 40 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.024 0C10.704 0 2.38918 6.69891 0.439559 15.8209C-0.534485 20.3789 0.121681 25.2256 2.29478 29.3499C4.38625 33.319 7.81505 36.5269 11.9174 38.3449C16.1845 40.2363 21.0833 40.5659 25.5683 39.2761C29.8951 38.0321 33.7316 35.3018 36.3435 31.6368C41.8045 23.9745 41.0744 13.2116 34.6495 6.34583C30.8839 2.32209 25.5356 0 20.024 0ZM29.6313 21.0968L24.1717 26.688C22.7895 28.1039 20.6047 25.9589 21.9813 24.5496L24.7361 21.7285H11.6811C10.754 21.7285 9.9805 20.9544 9.9805 20.0279C9.9805 19.1013 10.7545 18.3272 11.6811 18.3272H24.6708L21.8614 15.5184C20.4654 14.1224 22.6298 11.9574 24.0258 13.3534L29.6185 18.9456C30.2114 19.538 30.217 20.4973 29.6313 21.0968Z"
                          fill="url(#paint0_linear_1_231)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1_231"
                            x1="0"
                            y1="20.0251"
                            x2="40"
                            y2="20.0251"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#FF9800" />
                            <stop offset="1" stop-color="#FF4929" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src={tablet} alt="Tablet" className="tablet-image" />
        {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
      </div>
    </div>
  );
};

export default PromoPage;
