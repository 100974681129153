import React, { useState, useEffect } from "react";
import imageDetailPC from "../assets/images/imageDetailPC.png";
import imageDetailMobi from "../assets/images/imageDetailMobi.png";

const PromotionModal = ({ onClose }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <div className="thumb-popup-detail">
          <img
            className="image-popup-detail"
            src={isMobile ? imageDetailMobi : imageDetailPC}
            alt="Chi tiết chương trình"
          />
        </div>
      </div>
    </div>
  );
};

export default PromotionModal;
